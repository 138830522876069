import { gql, useMutation, useQuery } from '@apollo/client';
import { useToast } from 'components/ui/toast';
import {
	ConversationApiEntityType,
	Mutation,
	MutationConversationAddArgs,
	MutationConversationMarkReadArgs,
	MutationConversationMessageAddArgs,
	MutationConversationUpdateArgs,
	NewConversationRequest,
	NewMessageRequest,
	Query,
	QueryConversationGetArgs,
	UpdateConversationRequest,
} from 'middleware-types';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { useCommunicationsContext } from '../communications-provider';
import { CONVERSATION_FIELDS } from '../fragments.graphql';
import { CONVERSATION_SEARCH } from './conversation-search-hooks';

/**
 * hook to create a new conversation
 */
const CREATE_CONVERSATION = gql`
	${CONVERSATION_FIELDS}
	mutation CreateConversation(
		$entityType: ConversationApiEntityType!
		$entityId: String!
		$request: NewConversationRequest!
	) {
		conversationAdd(entityType: $entityType, entityId: $entityId, request: $request) {
			conversation {
				...ConversationFields
			}
		}
	}
`;

export const useCreateConversation = () => {
	const toast = useToast();
	const { entityType, entityId, setSelectedConversationId } = useCommunicationsContext();

	const [_createConversation] = useMutation<
		Pick<Mutation, 'conversationAdd'>,
		MutationConversationAddArgs
	>(CREATE_CONVERSATION, { refetchQueries: [CONVERSATION_SEARCH] });

	const createConversation = async (request: NewConversationRequest) => {
		return await _createConversation({ variables: { entityType, entityId, request } })
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Conversation created successfully.', { variant: 'success' });
				if (res.data) {
					setSelectedConversationId(res.data.conversationAdd.conversation.id);
				}
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return createConversation;
};

/**
 * hook to get a conversation
 */
const GET_CONVERSATION = gql`
	${CONVERSATION_FIELDS}
	query GetConversation(
		$entityType: ConversationApiEntityType!
		$entityId: String!
		$conversationId: String!
	) {
		conversationGet(
			entityType: $entityType
			entityId: $entityId
			conversationId: $conversationId
		) {
			...ConversationFields
		}
	}
`;

export const useConversation = (conversationId: string | null) => {
	const toast = useToast();
	const { entityType, entityId } = useCommunicationsContext();
	const markConversationRead = useMarkConversationRead();
	const { data, loading } = useQuery<Pick<Query, 'conversationGet'>, QueryConversationGetArgs>(
		GET_CONVERSATION,
		conversationId
			? {
					variables: { entityType, entityId, conversationId },
					onError: () => toast.push('Unable to load conversation', { variant: 'error' }),
					onCompleted: (data) => {
						if (
							data.conversationGet.messages.some((m) => {
								const currentParticipant = m.messageParticipants.find(
									(mp) => mp.entityId === entityId
								);
								return currentParticipant && !currentParticipant.firstReadUtc;
							})
						) {
							markConversationRead(conversationId);
						}
					},
			  }
			: {
					skip: true,
			  }
	);

	const conversation = data?.conversationGet;
	return { conversation, loading };
};

/**
 * hook to send a new message
 */
const SEND_MESSAGE = gql`
	${CONVERSATION_FIELDS}
	mutation SendMessage(
		$entityType: ConversationApiEntityType!
		$entityId: String!
		$conversationId: String!
		$request: NewMessageRequest!
	) {
		conversationMessageAdd(
			entityType: $entityType
			entityId: $entityId
			conversationId: $conversationId
			request: $request
		) {
			conversation {
				...ConversationFields
			}
		}
	}
`;

export const useSendMessage = () => {
	const toast = useToast();
	const {
		entityType,
		entityId,
		selectedConversationId: conversationId,
	} = useCommunicationsContext();

	const [_sendMessage, { loading }] = useMutation<
		Pick<Mutation, 'conversationMessageAdd'>,
		MutationConversationMessageAddArgs
	>(SEND_MESSAGE, { refetchQueries: [CONVERSATION_SEARCH] });

	const sendMessage = async (request: NewMessageRequest): Promise<boolean> => {
		if (conversationId === null) return false;
		return await _sendMessage({ variables: { entityType, entityId, conversationId, request } })
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { sendMessage, loading };
};

/**
 * hook to mark a conversation as read
 */
const MARK_CONVERSATION_READ = gql`
	mutation MarkConversationRead(
		$entityType: ConversationApiEntityType!
		$entityId: String!
		$conversationId: String!
	) {
		conversationMarkRead(
			entityType: $entityType
			entityId: $entityId
			conversationId: $conversationId
		) {
			conversationSummary {
				conversationId
				unreadMessageCount
			}
			conversation {
				id
				messages {
					id
					messageParticipants {
						id
						firstReadUtc
					}
				}
			}
		}
	}
`;

export const useMarkConversationRead = () => {
	const { entityType, entityId } = useCommunicationsContext();

	const [_markConversationRead] = useMutation<
		Pick<Mutation, 'conversationMarkRead'>,
		MutationConversationMarkReadArgs
	>(MARK_CONVERSATION_READ, {
		update: (cache) => {
			if (entityType == ConversationApiEntityType.Users) {
				cache.evict({
					id: 'ROOT_QUERY',
					fieldName: 'getUserBadgeCounts',
				});
			}

			if (entityType == ConversationApiEntityType.Organizations) {
				cache.evict({
					id: 'ROOT_QUERY',
					fieldName: 'getOrgBadgeCounts',
				});
			}

			cache.gc();
		},
	});

	const markConversationRead = (conversationId: string) => {
		_markConversationRead({
			variables: { entityType, entityId, conversationId },
		});
	};

	return markConversationRead;
};

/**
 * hook to update a conversation
 */
const UPDATE_CONVERSATION = gql`
	${CONVERSATION_FIELDS}
	mutation UpdateConversation(
		$entityType: ConversationApiEntityType!
		$entityId: String!
		$conversationId: String!
		$request: UpdateConversationRequest!
	) {
		conversationUpdate(
			entityType: $entityType
			entityId: $entityId
			conversationId: $conversationId
			request: $request
		) {
			conversation {
				...ConversationFields
			}
		}
	}
`;

export const useUpdateConversation = () => {
	const toast = useToast();
	const {
		entityType,
		entityId,
		selectedConversationId: conversationId,
	} = useCommunicationsContext();

	const [_updateConversation] = useMutation<
		Pick<Mutation, 'conversationUpdate'>,
		MutationConversationUpdateArgs
	>(UPDATE_CONVERSATION, { refetchQueries: [CONVERSATION_SEARCH] });

	const updateConversation = async (request: UpdateConversationRequest) => {
		if (conversationId === null) return false;
		return await _updateConversation({
			variables: { entityType, entityId, conversationId, request },
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Conversation updated successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return updateConversation;
};
