import { ConfirmationModalContent } from 'components/ui/modals/confirmation-modal-content';
import { ModalContentProps } from 'components/ui/modals/modal-types';
import { LabelType } from 'middleware-types';
import { useCommunicationsContext } from '../communications-provider';
import { useEmptyTrash } from '../hooks/conversation-labels-hooks';
import { useConversation } from '../hooks/conversations-hooks';

export const EmptyTrashModal = ({ onClose }: ModalContentProps) => {
	const { selectedConversationId, setSelectedConversationId, entityId } =
		useCommunicationsContext();

	const { conversation } = useConversation(selectedConversationId);

	const thisEntityHasSelectedConversationInTrash = conversation?.participants
		.find((participant) => participant.entityId === entityId)
		?.participantLabels.some((label) => label.type === LabelType.Trash);

	const { emptyTrash } = useEmptyTrash();

	const onSubmit = async () => {
		const success = await emptyTrash();
		if (success && thisEntityHasSelectedConversationInTrash) setSelectedConversationId(null);
		return success;
	};

	return (
		<ConfirmationModalContent
			variant="destructive"
			primaryText="Empty Trash?"
			secondaryText="All conversations will be permanently deleted from your inbox. This cannot be undone."
			confirmText="Empty"
			onSubmit={onSubmit}
			onClose={onClose}
		/>
	);
};
