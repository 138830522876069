import { Avatar, Box, Link, Stack, Typography } from '@mui/material';
import { TypingIndicator } from 'components/ui/typingIndicator';
import { UserChatFile, UserChatFootnote } from 'middleware-types';
import { ReactNode, useEffect, useRef } from 'react';
import Markdown from 'react-markdown';
import { useAccount } from 'utils/useAccount';
import { formatEvoxMessageDate } from 'utils/utils';
import { UserChatMessageExtended } from './chatHistory';
import { CopyButton } from './copyButton';
import { FeedbackButtons } from './feedbackButtons';
import { EvoxFileChip } from './evoxFileChip';
import { AssistantLink } from './assistantLink';

export interface ChatResponseProps {
	message: UserChatMessageExtended;
	selected: boolean;
	readOnly?: boolean;
	setFootnotes?: React.Dispatch<React.SetStateAction<UserChatFootnote[] | null>>;
	files?: UserChatFile[] | undefined;
}

export const ChatResponse = ({
	message,
	selected,
	readOnly = false,
	setFootnotes,
	files,
}: ChatResponseProps) => {
	let text = message.content;
	const footnotes = message.footnotes;

	return (
		<ChatResponseBase showBubble={message.showBubble} selected={selected} files={files}>
			<Markdown
				components={{
					p(props) {
						//eslint-disable-next-line
						const { children } = props;
						return <Typography variant="body2">{children}</Typography>;
					},
					img(props) {
						//eslint-disable-next-line
						const { src, alt } = props;
						return <img src={src} alt={alt} style={{ maxWidth: '100%' }} />;
					},
					li(props) {
						//eslint-disable-next-line
						const { children } = props;
						return <li style={{ fontSize: '0.875rem' }}>{children}</li>;
					},
					a(props) {
						//eslint-disable-next-line
						const { children, href } = props;
						return <AssistantLink url={href}>{children}</AssistantLink>;
					},
					pre(props) {
						//eslint-disable-next-line
						const { children } = props;
						return (
							<Box
								component="pre"
								border="1px solid"
								borderColor="divider"
								borderRadius={1}
								bgcolor="white"
								p={1}
								my={1}
								overflow="auto">
								{children}
							</Box>
						);
					},
					code(props) {
						//eslint-disable-next-line
						const { children } = props;
						return (
							<Typography
								variant="body2"
								fontFamily="monospace"
								bgcolor="white"
								component="code">
								{children}
							</Typography>
						);
					},
				}}>
				{text}
			</Markdown>
			{footnotes && footnotes.length > 0 && setFootnotes && (
				<Typography pt={2} variant="body2" sx={{ whiteSpace: 'pre-line' }}>
					<Link
						sx={{ 'color': 'navy.500', '&:visited': { color: 'navy.500' } }}
						underline="always"
						target="_blank"
						onClick={() => setFootnotes(footnotes)}>
						(Show {footnotes.length === 1 ? '1 source' : footnotes.length + ' sources'})
					</Link>
				</Typography>
			)}
			<Stack direction="row" justifyContent="space-between" alignItems="center">
				<Stack direction="row">
					<FeedbackButtons message={message} readOnly={readOnly} />
					{text && <CopyButton text={text} />}
				</Stack>
				<Typography variant="caption">
					{formatEvoxMessageDate(message.dateTimeUtc)}
				</Typography>
			</Stack>
		</ChatResponseBase>
	);
};

export const WelcomeMessage = ({ userId }: { userId: string }) => {
	const { account } = useAccount(userId);
	const name = account?.name?.firstName ?? '';
	return (
		<ChatResponseBase>
			<Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
				Hi, {name}. I&apos;m EVO-X, your personal AI assistant. How can I help you?
			</Typography>
		</ChatResponseBase>
	);
};

export const ChatResponseLoading = () => {
	return (
		<ChatResponseBase>
			<Box p={1}>
				<TypingIndicator />
			</Box>
		</ChatResponseBase>
	);
};

export const ChatResponseBase = ({
	selected = false,
	children,
	showBubble = true,
	files,
}: {
	selected?: boolean;
	children: ReactNode;
	showBubble?: boolean;
	files?: UserChatFile[] | undefined;
}) => {
	const containerRef = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (!selected) return;
		containerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
	}, [selected]);

	return (
		<Stack direction="row" spacing={1} alignItems="flex-start" ref={containerRef}>
			{showBubble ? (
				//width="40" height="40"
				<Avatar
					src="/img/evoxLogo.svg"
					sx={{ '& img': { objectFit: 'contain', height: '85%' } }}
				/>
			) : (
				<Stack minWidth={40}></Stack>
			)}
			<Stack alignItems="flex-start" minWidth={0}>
				{/* {
					// A sent message's files will always be an array, but might be empty.
					// A pending chat message's files might be undefined, in keeping with
					files &&
						files.length > 0 &&
						files.map((file) => <EvoxFileChip key={file.chatFileId} name={file.name} />)
				} */}
				<Box
					borderRadius={1}
					p={1}
					sx={{
						backgroundColor: 'primary.light',
						border: selected ? '2px solid' : undefined,
						borderColor: selected ? 'primary.main' : undefined,
						overflowWrap: 'break-word',
						overflowX: 'auto',
						width: '100%',
					}}>
					{children}
				</Box>
			</Stack>
		</Stack>
	);
};
